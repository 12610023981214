import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const hasClass = (el, cls) => {
  return [...el.classList].includes(cls); //cls without dot
};

export const formatFullDate = (str, item, every) => {
  for (let i = 0; i < str.length; i++) {
    if (!(i % (every + 1))) {
      str = str.substring(0, i) + item + str.substring(i);
    }
  }

  return str.substring(1);
};

export const GetUserLocation = () => {
  const router = useRouter();
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Check if the Geolocation API is available in the browser
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Handle successful location retrieval
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          // Handle location retrieval error
          console.error('Error getting user location:', error);
          setUserLocation(null);
        }
      );
    } else {
      // Geolocation API is not available in the browser
      console.error('Geolocation is not supported in this browser');
      setUserLocation(null);
    }
  }, []);

  return userLocation
    ? `Latitude: ${userLocation.latitude}, Longitude: ${userLocation.longitude}`
    : `User location not available.`;
};

export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};

// Regular expression pattern to match a URL
export const urlPattern =
  /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;

export const formatSortCode = (sortCode) => {
  // const sortCodeStr = String(sortCode);

  // Use regular expressions to split into groups of 2 digits
  const formattedSortCode = sortCode.replace(
    /(\d{2})(\d{2})(\d{2})/,
    '$1-$2-$3'
  );

  return formattedSortCode;
};

export const capitalizeString = (string: string | string[]) => {
  if (string) {
    return Array.isArray(string)
      ? string.map((str) => str[0].toUpperCase() + str.substring(1))
      : string[0].toUpperCase() + string.substring(1);
  }
};

export const formatCardNumber = (cardNumber) => {
  // Remove any non-digit characters
  const digitsOnly = cardNumber.replace(/\D/g, '');

  // Format the card number as XXXX-XXXX-XXXX-XXXX
  const formattedCardNumber = digitsOnly.replace(
    /(\d{4})(\d{4})(\d{4})(\d{4})/,
    '$1-$2-$3-$4'
  );

  return formattedCardNumber;
};

export const validateCardDate = (input) => {
  if (
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      input
    )
  ) {
    return true;
  }

  return false;
};

export const validateEmail = (value) => {
  const emailValidation =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const emailTest = emailValidation.test(String(value).toLowerCase());

  return emailTest;
};

export const validatePassword = (password) => {
  // Define the rules for password validation
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

  // Initialize an empty error message
  let errorMessage = '';

  // Check each rule and build the error message
  if (password.length < minLength) {
    errorMessage += 'Password must be at least 8 characters long. ';
  }
  if (!hasUppercase) {
    errorMessage += 'Password must contain at least one uppercase letter. ';
  }
  if (!hasLowercase) {
    errorMessage += 'Password must contain at least one lowercase letter. ';
  }
  if (!hasSpecialChar) {
    errorMessage += 'Password must contain at least one special character. ';
  }

  // Check if the password meets all the rules
  const isValid = errorMessage === '';

  return { isValid, errorMessage };
};

export const removeLeadingZero = (mobileNo) => {
  // Check if the string starts with '0'
  if (mobileNo.startsWith('0')) {
    // If it starts with '0', remove the leading '0'
    return mobileNo.slice(1);
  } else {
    // If it doesn't start with '0', keep the same value
    return mobileNo;
  }
};

export const hasNonNullProperties = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null) {
      return true; // Found a non-null property
    }
  }
  return false; // All properties are null
};

export const pad = (str, max) => {
  return str.length < max ? pad('0' + str, max) : str;
};

export const limitter = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
};

export const reducerAmount = (accumulator, currentValue) =>
  accumulator + currentValue;

// Example set of airport-related keywords and IATA codes (this list can be extended)
const airportKeywords = [
  'airport',
  'terminal',
  'airfield',
  'international',
  'domestic',
  'aviation',
];

// Sample IATA codes (extend with actual IATA codes if necessary)
const airportIATACodes = [
  'LHR',
  'LTN',
  'LGW',
  'STN',
  'XET',
  'XIT',
  'ZDU',
  'LCY',
  'SEN',
];

export const isAirportAddress = (address: string): boolean => {
  // Normalize the address by converting to lowercase for keyword matching
  const normalizedAddress = address.toLowerCase();

  // Check if any of the common airport-related keywords exist in the address
  const hasAirportKeyword = airportKeywords.some((keyword) =>
    normalizedAddress.includes(keyword)
  );

  // Check if any known IATA codes are present in the address (ignoring case sensitivity)
  const hasIATACode = airportIATACodes.some((code) =>
    address.toUpperCase().includes(code)
  );

  // Return true if the address contains any airport keywords or IATA codes
  return hasAirportKeyword || hasIATACode;
};

// Function to copy text to clipboard
export const copyToClipboard = (text) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text);
  } else {
    console.error('Clipboard API not supported');
    return Promise.reject('Clipboard API not supported');
  }
};
